import {reactive} from "@vue/reactivity";

export interface VideoHelpStoreState {
    stream: MediaStream | null
    callID: string | null
}

class VideoHelpStore {
    protected state = reactive<VideoHelpStoreState>({
        stream: null,
        callID: null
    })

    public resetState() {
        this.state.stream?.getTracks().forEach(stream => stream.stop())
        this.state.stream = null
        this.state.callID = null
    }

    public getState() {
        return reactive(this.state)
    }

    public isStreamActive() {
        return !!this.state.stream?.active
    }

    public setCallID(callID: string) {
        this.state.callID = callID
    }

    public async setStream() {
        this.state.stream = await navigator.mediaDevices.getDisplayMedia({audio: false, video: true})
        return this.state.stream
    }

    public disconnectStream() {
        this.resetState()
    }
}

const store = new VideoHelpStore()
export const useVideoHelpStore = (): VideoHelpStore => store
