import {addManagerFun, deleteManagerFun, editAuthUserFun, getManagersFun} from "@/firebase/functions";
import {AddUserInput, EditUserInput} from "@/shared/types";
import {reactive, readonly} from "vue";
import {TYPE} from "vue-toastification";
import {useAppStore} from "../app/AppStore";
import {ManagersStoreState} from './types'

class ManagersStore {
    private appStore = useAppStore()

    protected state = reactive<ManagersStoreState>({
        managers: [],
        loaded: false
    })

    public resetState() {
        this.state.managers = []
        this.state.loaded = false
    }

    public getState() {
        return readonly(this.state)
    }

    public async setManagers(force: boolean) {
        if (!this.state.loaded || force) {
            try {
                this.state.managers = await getManagersFun()
                this.state.loaded = true
            } catch (err: any) {
                this.appStore.showToast(TYPE.ERROR, err.message)
            }
        }
    }

    public async addManager(newManager: AddUserInput) {
        try {
            await addManagerFun(newManager)
            await this.setManagers(true)
            this.appStore.showToast(TYPE.SUCCESS, 'Manager lisatud')
        } catch (err: any) {
            this.appStore.showToast(TYPE.ERROR, err.message)
        }
    }

    public async deleteManager(email: string) {
        try {
            await deleteManagerFun(email)
            await this.setManagers(true)
            this.appStore.showToast(TYPE.SUCCESS, 'Manager kustutatud')
        } catch (err: any) {
            this.appStore.showToast(TYPE.ERROR, err.message)
        }
    }

    public async editManager(user: EditUserInput) {
        try {
            await editAuthUserFun(user)
            await this.setManagers(true)
            this.appStore.showToast(TYPE.SUCCESS, 'Kasutaja edukalt muudetud')
        } catch (err: any) {
            this.appStore.showToast(TYPE.ERROR, err.message)
        }
    }
}

const store = new ManagersStore()
export const useManagersStore = (): ManagersStore => store
