export enum RouteName {
    HOME = 'Home',
    FORGOT_PASSWORD = 'ForgotPassword',
    DASHBOARD = 'Dashboard',
    DASHBOARD_HOME = "DashboardHome",
    ADMINISTRATORS = "Administrators",
    MANAGERS = "Managers",
    CONTACT = 'Contact',
    SETTINGS = "Settings",
    DOCUMENTATION = "Documentation",
    DOCUMENTATION_HOME = "DocumentationHome",
    DOCUMENTATION_INSTALL = "DocumentationInstall",
    DOCUMENTATION_UNINSTALL = "DocumentationUninstall",
    DOCUMENTATION_SCREEN_SHARE = "DocumentationScreenShare",
    DOCUMENTATION_USER_MANUAL ="DocumentationUserManual",
    CLINICS = "Clinics",
    CLINICS_HOME = 'ClinicsHome',
    CLINICS_WORKERS = "ClinicsWorkers",
    CLINICS_CABINETS = "ClinicsCabinets",
    CLINICS_X_RAY_EQUIPMENTS = "ClinicsXRayEquipments",
    CLINICS_X_RAY_IMAGES = "ClinicsXRayImages",
    CLINICS_REPORTS = "ClinicsReports",
    CLINICS_SETTINGS = "ClinicsSettings",
    VIDEO_HELP = "VideoHelp",
    VIDEO_HELP_HOME = 'VideoHelpHome',
    VIDEO_HELP_SHARE = "VideoHelpShare",
    VIDEO_HELP_JOIN = "VideoHelpJoin",
    INSTALL = "Install"
}

export enum RoutePath {
    HOME = '/',
    FORGOT_PASSWORD = '/forgot-password',
    DASHBOARD = '/dashboard',
    DASHBOARD_HOME = '',
    ADMINISTRATORS = '/dashboard/administrators',
    MANAGERS = '/dashboard/managers',
    SETTINGS = '/dashboard/settings',
    CONTACT = '/dashboard/contact',
    DOCUMENTATION = '/dashboard/documentation',
    DOCUMENTATION_HOME = '',
    DOCUMENTATION_INSTALL = "/dashboard/documentation/install",
    DOCUMENTATION_UNINSTALL = "/dashboard/documentation/uninstall",
    DOCUMENTATION_SCREEN_SHARE = "/dashboard/documentation/screen-share",
    DOCUMENTATION_USER_MANUAL = "/dashboard/documentation/user-manual",
    CLINICS = "/dashboard/clinics",
    CLINICS_HOME = "",
    CLINISC_WORKERS = '/dashboard/clinics/workers',
    CLINICS_CABINETS = '/dashboard/clinics/cabinets',
    CLINICS_X_RAY_EQUIPMENTS = '/dashboard/clinics/x-ray-equipments',
    CLINICS_X_RAY_IMAGES = "/dashboard/clinics/x-ray-images",
    CLINICS_REPORTS = '/dashboard/clinics/reports',
    CLINICS_SETTINGS = '/dashboard/clinics/settings',
    VIDEO_HELP ="/dashboard/video-help",
    VIDEO_HELP_HOME = '',
    VIDEO_HELP_SHARE = "/dashboard/video-help/share",
    VIDEO_HELP_JOIN = "/dashboard/video-help/join",
    INSTALL = "/installeerimine"
}
