import { WorkerDto} from "@/shared/types";

export interface WorkersStoreState {
  workers: WorkerDto[]
  pastWorkers: WorkerDto[]
  loaded: boolean,
  pastWorkersLoaded: boolean
  maxWorkerCountWarning: MaxWorkerCountWarningType | undefined
}

export enum MaxWorkerCountWarningType {
  ADD_NEW = 'ADD_NEW',
  TOGGLE_STATUS= "TOGGLE_STATUS"
}
