import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import {useEquipmentsStore} from "@/store/equipments/EquipmentsStore";
import {useManagersStore} from "@/store/managers/ManagerStore";
import {useSnapshotsStore} from "@/store/snapshots/SnapshotsStore";
import {useWorkersStore} from "@/store/workers/WorkersStore";
import {useXRayEquipmentsStore} from "@/store/xrayequipments/XRayEquipmentsStore";
import {useAdminsStore} from "@/store/admins/AdminsStore";
import {useCabinetsStore} from "@/store/cabinets/CabinetsStore";
import {useImagesStore} from "@/store/images/ImagesStore";
import {useContactsStore} from "@/store/contacts/ContactsStore";
import {useNotificationsStore} from "@/store/notifications/NotificationsStore";
import {useVideoHelpStore} from "@/store/videohelp/VideoHelpStore";

class SharedStore {
    private clinicsStore = useClinicsStore()
    private equipmentsStore = useEquipmentsStore()
    private managersStore = useManagersStore()
    private snapshotsStore = useSnapshotsStore()
    private workersStore = useWorkersStore()
    private xRayEquipmentsStore = useXRayEquipmentsStore()
    private adminsStore = useAdminsStore()
    private cabinetsStore = useCabinetsStore()
    private imagesStore = useImagesStore()
    private contactsStore = useContactsStore()
    private notificationsStore = useNotificationsStore()
    private videoHelpStore = useVideoHelpStore()

    public resetAllStores() {
        this.clinicsStore.resetState()
        this.equipmentsStore.resetState()
        this.managersStore.resetState()
        this.snapshotsStore.resetState()
        this.workersStore.resetState()
        this.xRayEquipmentsStore.resetState()
        this.adminsStore.resetState()
        this.cabinetsStore.resetState()
        this.imagesStore.resetState()
        this.contactsStore.resetState()
        this.notificationsStore.resetState()
        this.videoHelpStore.resetState()
    }
}

const sharedStore = new SharedStore()
export const useSharedStore = (): SharedStore => sharedStore
