
import router from "@/router";
import {RouteName} from "@/router/types";
import {defineComponent, ref} from "vue";
import {signIn} from "@/firebase/auth";
import {useAppStore} from "@/store/app/AppStore";
import {TYPE} from "vue-toastification";

export default defineComponent({
  name: RouteName.HOME,
  setup() {
    const email = ref<string | null>(null);
    const password = ref<string | null>(null);
    const appStore = useAppStore();

    const errorCodes: Record<string, string> = {
      'auth/invalid-email': 'Vigane e-mail',
      'auth/user-disabled': "Kasutaja ei ole aktiivne",
      'auth/user-not-found': "Kasutajat ei leitud",
      'auth/wrong-password': "Vale parool"
    }

    const tryLogin = async (email: string, password: string) => {
      try {
        // TODO: clear store
        await signIn(email, password);
        await router.push({name: RouteName.DASHBOARD_HOME});
      } catch (e: any) {
        const code: string = e.code
        console.log(e)
        appStore.showToast(TYPE.ERROR, code in errorCodes ? errorCodes[code] : 'Miskit läks valesti');
      }
    };
    const onLoginAttempt = async () => {
      if (!email.value || !password.value) return
      await tryLogin(email.value, password.value);
    };

    const forgotPassword = () => router.push({name: RouteName.FORGOT_PASSWORD})

    return {email, password, onLoginAttempt, forgotPassword};
  },
});
