import {useAppStore} from "@/store/app/AppStore";
import {reactive, readonly} from "vue";
import {SnapshotsStoreState} from "@/store/snapshots/types";
import {addSnapshotsFun, getSnapshotsFun, getXRayImagesByIDsFun} from "@/firebase/functions";
import {TYPE} from "vue-toastification";
import {AddSnapshotInput} from "@/shared/types";

class SnapshotsStore {
    private appStore = useAppStore()
    private state = reactive<SnapshotsStoreState>({
        snapshots: [],
        loaded: false,
        images: []
    })

    public resetState() {
        this.state.snapshots = []
        this.state.loaded = false
        this.state.images = []
    }

    public getState() {
        return readonly(this.state)
    }

    public async setSnapshots(force: boolean, withLoader = true) {
        if (!this.state.loaded || force) {
            try {
                this.state.snapshots = await getSnapshotsFun(withLoader)
                const imagesUIDs = [...new Set(this.state.snapshots.flatMap(snapshot => snapshot.pictures.map(picture => picture.xRayImageId)))]
                this.state.images = imagesUIDs.length ? await getXRayImagesByIDsFun(imagesUIDs, withLoader) : []
                this.state.loaded = true
            } catch (e: any) {
                this.appStore.showToast(TYPE.ERROR, e.message)
            }
        }
    }

    public async addSnapshots(snapshots: AddSnapshotInput[]) {
        try {
            await addSnapshotsFun(snapshots)
            await this.setSnapshots(true)
            this.appStore.showToast(TYPE.SUCCESS, 'Ülesvõtted lisatud!')
        } catch (err: any) {
            this.appStore.showToast(TYPE.ERROR, err.message)
        }
    }

}

const store = new SnapshotsStore()
export const useSnapshotsStore = (): SnapshotsStore => store
