import {functions, storage} from '@/firebase/config'
import {
    AddCabinetInput,
    AddClinicInput,
    AddUserInput,
    AddWorkerInput,
    AddXRayEquipmentInput,
    AddXRayImageInput,
    CabinetDto,
    ClinicDto,
    EditClinicInput,
    EquipmentDto,
    FunctionName,
    GetAdminResult,
    GetUserResult,
    ToggleSuperAdminRoleDto,
    WorkerDto,
    XRayEquipmentDto,
    EditWorkerInput,
    EditCabinetInput,
    EditXRayEquipmentInput,
    SnapshotDTO,
    XRayImageDto,
    ToggleWorkerStatusInput,
    AddSnapshotInput, EditUserInput, AddContactInput, NotificationDTO
} from '@/shared/types';
import {useAppStore} from '@/store/app/AppStore';


const appStore = useAppStore()

export function updateAuthUserPhoneFun(email: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const updateAuthUserPhone = functions.httpsCallable(FunctionName.UPDATE_AUTH_USER_PHONE)
        updateAuthUserPhone(email)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function editAuthUserFun(user: EditUserInput): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const editAuthUser = functions.httpsCallable(FunctionName.EDIT_AUTH_USER)
        editAuthUser(user)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function addAdminFun(newAdmin: AddUserInput): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const addAdmin = functions.httpsCallable(FunctionName.ADD_ADMIN)
        addAdmin(newAdmin)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function addManagerFun(newManager: AddUserInput): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const addManager = functions.httpsCallable(FunctionName.ADD_MANAGER)
        addManager(newManager)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function getAdminsFun(): Promise<GetAdminResult[]> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const getAdmins = functions.httpsCallable(FunctionName.GET_ADMINS)
        getAdmins()
            .then((result: { data: GetAdminResult[] }) => {
                appStore.removeLoading(loadingID)
                resolve(result.data)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function getManagersFun(): Promise<GetUserResult[]> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const getManagers = functions.httpsCallable(FunctionName.GET_MANAGERS)
        getManagers()
            .then((result: { data: GetUserResult[] }) => {
                appStore.removeLoading(loadingID)
                resolve(result.data)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function setSuperAdminFun(data: ToggleSuperAdminRoleDto): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const toggleSuperAdmin = functions.httpsCallable(FunctionName.TOGGLE_SUPER_ADMIN_ROLE)
        toggleSuperAdmin(data)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch((err) => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function deleteAdminFun(email: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const deleteAdmin = functions.httpsCallable(FunctionName.DELETE_ADMIN)
        deleteAdmin(email)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function deleteManagerFun(email: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const deleteManager = functions.httpsCallable(FunctionName.DELETE_MANAGER)
        deleteManager(email)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function addClinicFun(clinic: AddClinicInput): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const addClinic = functions.httpsCallable(FunctionName.ADD_CLINIC)
        addClinic(clinic)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function getClinicsFun(): Promise<ClinicDto[]> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const getClinics = functions.httpsCallable(FunctionName.GET_CLINICS)
        getClinics()
            .then((result: { data: ClinicDto[] }) => {
                appStore.removeLoading(loadingID)
                resolve(result.data)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })

    })
}

export function deleteClinicFun(data: { clinicUID: string }): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const deleteClinic = functions.httpsCallable(FunctionName.DELETE_CLINIC)
        deleteClinic(data)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function addWorkerFun(worker: AddWorkerInput): Promise<{ isMaxActive: boolean }> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const addWorker = functions.httpsCallable(FunctionName.ADD_WORKER)
        addWorker(worker)
            .then((result: { data: { isMaxActive: boolean } }) => {
                appStore.removeLoading(loadingID)
                resolve(result.data)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function getWorkersFun(): Promise<WorkerDto[]> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const getWorkers = functions.httpsCallable(FunctionName.GET_WORKERS)
        getWorkers()
            .then((result: { data: WorkerDto[] }) => resolve(result.data))
            .catch(err => reject(err))
            .finally(() => appStore.removeLoading(loadingID))

    })
}

export function getPastWorkersFun(): Promise<WorkerDto[]> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const getPastWorkers = functions.httpsCallable(FunctionName.GET_PAST_WORKERS)
        getPastWorkers()
            .then((result: { data: WorkerDto[] }) => resolve(result.data))
            .catch(err => reject(err))
            .finally(() => appStore.removeLoading(loadingID))
    })
}

export function editWorkerFun(worker: EditWorkerInput): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const editWorker = functions.httpsCallable(FunctionName.EDIT_WORKER)
        editWorker(worker)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function toggleWorkerStatusFun(data: ToggleWorkerStatusInput): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const toggleWorkerStatus = functions.httpsCallable(FunctionName.TOGGLE_WORKER_STATUS)
        toggleWorkerStatus(data)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            }).catch(err => {
            appStore.removeLoading(loadingID)
            reject(err)
        })
    })
}

export function deleteWorkerFun(data: { clinicUID: string, workerUID: string }): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const deleteWorker = functions.httpsCallable(FunctionName.DELETE_WORKER)
        deleteWorker(data)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })

    })
}

export function addCabinetFun(cabinet: AddCabinetInput): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const addCabinet = functions.httpsCallable(FunctionName.ADD_CABINET)
        addCabinet(cabinet)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function getCabinetsFun(): Promise<CabinetDto[]> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const getCabinets = functions.httpsCallable(FunctionName.GET_CABINETS)
        getCabinets()
            .then((result: { data: CabinetDto[] }) => {
                appStore.removeLoading(loadingID)
                resolve(result.data)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function deleteCabinetFun(data: { cabinetUID: string, clinicUID: string }): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const deleteCabinet = functions.httpsCallable(FunctionName.DELETE_CABINET)
        deleteCabinet(data)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })

    })
}

export function editCabinetFun(cabinet: EditCabinetInput): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const editCabinet = functions.httpsCallable(FunctionName.EDIT_CABINET)
        editCabinet(cabinet)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function getEquipmentsFun(): Promise<EquipmentDto[]> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const getEquipments = functions.httpsCallable(FunctionName.GET_EQUIPMENTS)
        getEquipments()
            .then((result: { data: EquipmentDto[] }) => {
                appStore.removeLoading(loadingID)
                resolve(result.data)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function addXRayEquipmentFun(newXRayEquipment: AddXRayEquipmentInput): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const addXRayEquipment = functions.httpsCallable(FunctionName.ADD_X_RAY_EQUIPMENT)
        addXRayEquipment(newXRayEquipment)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function getXRayEquipmentsFun(): Promise<XRayEquipmentDto[]> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const getXRayEquipments = functions.httpsCallable(FunctionName.GET_X_RAY_EQUIPMENTS)
        getXRayEquipments()
            .then((result: { data: XRayEquipmentDto[] }) => {
                appStore.removeLoading(loadingID)
                resolve(result.data)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function deleteXRayEquipmentFun(xRayEquipmentUID: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const deleteXRayEquipment = functions.httpsCallable(FunctionName.DELETE_X_RAY_EQUIPMENT)
        deleteXRayEquipment(xRayEquipmentUID)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function editXRayEquipmentFun(xRayEquipment: EditXRayEquipmentInput): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const editXRayEquipment = functions.httpsCallable(FunctionName.EDIT_X_RAY_EQUIPMENT)
        editXRayEquipment(xRayEquipment)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function getAllXRayImagesFun(): Promise<XRayImageDto[]> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const getAllXRayImages = functions.httpsCallable(FunctionName.GET_ALL_X_RAY_IMAGES)
        getAllXRayImages()
            .then((result: { data: XRayImageDto[] }) => {
                appStore.removeLoading(loadingID)
                resolve(result.data)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function addXRayImagesFun(images: AddXRayImageInput[]): Promise<{ data: string[] }> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const addXRayImages = functions.httpsCallable(FunctionName.ADD_X_RAY_IMAGES)
        addXRayImages({images})
            .then((data) => {

                appStore.removeLoading(loadingID)
                resolve(data)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function getXRayImagesByIDsFun(imageUIDs: string[], withLoader: boolean): Promise<XRayImageDto[]> {
    return new Promise((resolve, reject) => {
        const loadingID = withLoader ? appStore.addLoading() : undefined
        const getXRayImagesByIDs = functions.httpsCallable(FunctionName.GET_X_RAY_IMAGES_BY_IDS)
        getXRayImagesByIDs({imageUIDs})
            .then((result: { data: XRayImageDto[] }) => resolve(result.data))
            .catch(err => reject(err))
            .finally(() => {
                if (loadingID) appStore.removeLoading(loadingID)
            })
    })
}

export function editClinicFun(clincic: EditClinicInput): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const editClinic = functions.httpsCallable(FunctionName.EDIT_CLINIC)
        editClinic(clincic)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {

                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function editClinicSettingsFun(clinic: ClinicDto): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const editClinicSettings = functions.httpsCallable(FunctionName.EDIT_CLINIC_SETTINGS)
        editClinicSettings(clinic)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function getSnapshotsFun(withLoader: boolean): Promise<SnapshotDTO[]> {
    return new Promise((resolve, reject) => {
        const loadingID = withLoader ? appStore.addLoading() : undefined
        const getSnapshots = functions.httpsCallable(FunctionName.GET_SNAPSHOTS)
        getSnapshots()
            .then((result: { data: SnapshotDTO[] }) => resolve(result.data))
            .catch(err => reject(err))
            .finally(() => {
                if (loadingID) appStore.removeLoading(loadingID)
            })
    })
}

export function addSnapshotsFun(snapshots: AddSnapshotInput[]): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const addSnapshots = functions.httpsCallable(FunctionName.ADD_SNAPSHOTS)
        addSnapshots({snapshots})
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            })
            .catch(err => {
                appStore.removeLoading(loadingID)
                reject(err)
            })
    })
}

export function addContactFun(contact: AddContactInput): Promise<undefined> {
    return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const addContact = functions.httpsCallable(FunctionName.ADD_CONTACT)
        addContact(contact)
            .then(() => {
                appStore.removeLoading(loadingID)
                resolve(undefined)
            }).catch(err => {
            appStore.removeLoading(loadingID)
            reject(err)
        })
    })
}

export function getNotificationsFun(withLoader: boolean): Promise<NotificationDTO[]> {
    return new Promise((resolve, reject) => {
        const loadingID = withLoader ? appStore.addLoading() : undefined
        const getNotifications = functions.httpsCallable(FunctionName.GET_NOTIFICATIONS)
        getNotifications()
            .then((result: { data: NotificationDTO[] }) => resolve(result.data))
            .catch(err => reject(err))
            .finally(() => {
                if (loadingID) appStore.removeLoading(loadingID)
            })
    })
}

export function getToothyDownloadURL(): Promise<string> {
        return new Promise((resolve, reject) => {
        const loadingID = appStore.addLoading()
        const toothyRef = storage.refFromURL('gs://toothy-admin.appspot.com/toothy-tron-Setup-0.2.36.exe')
        toothyRef.getDownloadURL()
            .then((url) => resolve(url))
            .catch(err => reject(err))
            .finally(() => appStore.removeLoading(loadingID))
    })

}


