import {addClinicFun, deleteClinicFun, editClinicFun, editClinicSettingsFun, getClinicsFun} from "@/firebase/functions";
import {AddClinicInput, ClinicDto, EditClinicInput} from "@/shared/types";
import {reactive, readonly} from "@vue/reactivity";
import {TYPE} from "vue-toastification";
import {useAppStore} from "../app/AppStore";
import {ClinicsStoreState} from "./types";

class ClinicsStore {
    private appStore = useAppStore()

    protected state = reactive<ClinicsStoreState>({
        clinics: [],
        loaded: false,
        activeClinicUID: undefined
    })

    public resetState() {
        this.state.clinics = []
        this.state.loaded = false
        this.state.activeClinicUID = undefined
    }

    public getState() {
        return readonly(this.state)
    }

    public async setClinics(force: boolean) {
        if (!this.state.loaded || force) {
            try {
                this.state.clinics = await getClinicsFun()
                this.state.loaded = true
            } catch (err: any) {
                this.appStore.showToast(TYPE.ERROR, err.message)
            }

        }
    }

    public async addClinic(newClinic: AddClinicInput) {
        try {
            await addClinicFun(newClinic)
            await this.setClinics(true)
            this.setActiveClinicUID(undefined)
            this.appStore.showToast(TYPE.SUCCESS, 'Kliinik lisatud')
        } catch (err: any) {
            this.appStore.showToast(TYPE.ERROR, err.message)
        }
    }

    public async deleteClinic(data: { clinicUID: string }) {
        try {
            await deleteClinicFun(data)
            await this.setClinics(true)
            if (data.clinicUID === this.state.activeClinicUID) this.setActiveClinicUID(undefined)
            await this.appStore.showToast(TYPE.SUCCESS, 'Kliinik kustutatud')
        } catch (e: any) {
            this.appStore.showToast(TYPE.ERROR, e.message)
        }
    }

    public async editClinic(clinic: EditClinicInput) {
        try {
            await editClinicFun(clinic)
            await this.setClinics(true)
            this.appStore.showToast(TYPE.SUCCESS, 'Kliinik muudetud')
        } catch (err: any) {
            this.appStore.showToast(TYPE.ERROR, err.message)
        }
    }

    public async editClinicSettings(clinic: ClinicDto) {
        try {
            await editClinicSettingsFun(clinic)
            await this.setClinics(true)
            this.appStore.showToast(TYPE.SUCCESS, 'Kliiniku seaded muudetud')
        } catch (err: any) {
            this.appStore.showToast(TYPE.ERROR, err.message)
        }
    }

    public setActiveClinicUID(clinicUID?: string) {
        this.state.activeClinicUID = clinicUID
    }
}

const store = new ClinicsStore()
export const useClinicsStore = (): ClinicsStore => store
