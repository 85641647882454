import {useAppStore} from "@/store/app/AppStore";
import {reactive} from "vue";
import {EquipmentsStoreState} from "@/store/equipments/types";
import {getEquipmentsFun} from "@/firebase/functions";
import {TYPE} from "vue-toastification";

class EquipmentsStore {
    private appStore = useAppStore()
    protected state = reactive<EquipmentsStoreState>({
        equipments: [],
        loaded: false
    })

    public resetState(){
        this.state.equipments = []
        this.state.loaded = false
    }

    public getState() {
        return reactive(this.state)
    }

    public async setEquipments(force: boolean) {
        if (!this.state.loaded || force) {
            try {
                this.state.equipments = await getEquipmentsFun()
                this.state.loaded = true
            } catch (err: any) {
                this.appStore.showToast(TYPE.ERROR, err.message)
            }
        }
    }
}

const store = new EquipmentsStore()
export const useEquipmentsStore = (): EquipmentsStore => store
