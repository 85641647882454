
import { computed, defineComponent } from 'vue'
import { auth } from './firebase/config'
import router from './router'
import { RouteName } from './router/types'
import firebase from 'firebase/app'
import { useUserStore } from '@/store/user/UserStore'
import { Claims } from './store/user/types'
import Spinner from './components/utils/app/Spinner.vue'
import { useAppStore } from './store/app/AppStore'
import { useSharedStore } from "@/store/shared/SharedStore";
import { useRoute } from 'vue-router'

type User = firebase.User
export default defineComponent({
  components: { Spinner },
  setup() {
    const userStore = useUserStore()
    const appStore = useAppStore()
    const appState = appStore.getState()
    const loading = computed(() => !!appState.loading.length)
    const sharedStore = useSharedStore()
    const route = useRoute()

    const userLoggedIn = async (user: User) => {
      sharedStore.resetAllStores()
      user.getIdTokenResult().then((idTokenResult) => {
        const claims: Claims = idTokenResult.claims as Claims
        const name = user.displayName || undefined
        const email = user.email || undefined
        userStore.setUser({ claims, name, email })
      })
      await router.push({ name: RouteName.DASHBOARD_HOME })
    }
    const userLoggedOut = async () => {
      const currentRoute = route.name
      if (currentRoute === RouteName.INSTALL) {
        sharedStore.resetAllStores()
        userStore.resetState()
        return
      }
      await router.push({ name: RouteName.HOME })
      sharedStore.resetAllStores()
      userStore.resetState()
    }

    auth.onAuthStateChanged((user) => (user ? userLoggedIn(user) : userLoggedOut()))

    return { loading }
  }
})
