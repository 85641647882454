import {useAppStore} from "@/store/app/AppStore";
import {reactive, readonly} from "vue";
import {NotificationsStoreState} from "@/store/notifications/types";
import {getNotificationsFun} from "@/firebase/functions";
import {TYPE} from "vue-toastification";
import {DeepReadonly, UnwrapNestedRefs} from "@vue/reactivity";

export class NotificationsStore {
    private appStore = useAppStore()

    protected state = reactive<NotificationsStoreState>({
        notifications: [],
        loaded: false
    })

    public resetState(): void {
        this.state.notifications = []
        this.state.loaded = false
    }

    public getState(): DeepReadonly<UnwrapNestedRefs<NotificationsStoreState>> {
        return readonly(this.state)
    }

    public async setNotifications(force: boolean): Promise<void> {
        if (!this.state.loaded || force) {
            try {
                this.state.notifications = await getNotificationsFun(false)
                this.state.loaded = true
            } catch (err: any) {
                this.appStore.showToast(TYPE.ERROR, err.message)
            }
        }
    }
}

const store = new NotificationsStore()
export const useNotificationsStore = (): NotificationsStore => store
