import {reactive, readonly} from "@vue/reactivity";
import {AppStoreState} from "./types";
import {TYPE, useToast} from "vue-toastification";
import firebase from "firebase";
import messaging = firebase.messaging;

export enum ErrorType {
    NO_PERMISSION = 'Antud tegevuseks puuduvad õigused',
    INVALID_NAME = 'Vigane nimi',
    INVALID_CLINIC_LICENCE_NO = 'Vigane litsentsi number',
    CABINET_NAME_NOT_UNIQUE = 'Sellise nimega kabinet on juba loodud',
    INVALID_RADIATION_PRACTICE_LICENCE_HOLDER = 'Vigane litsenti hoidja nimi',
    INVALID_USERS_COUNT = 'Vigane kasutajate arv',
    INVALID_LICENSE_NOS = 'Vigased litsentsi numbrid',
    CLINIC_EXISTS = 'Sellise nimega kliinik on juba loodud',
    NO_TOPIC = 'Puudub kirja teema',
    NO_CONTENT = 'Puudub kirja sisu',
    INVALID_SNAPSHOTS = 'Vigased ülesvõtted',
    INVALID_PERSONAL_NO = 'Vigane isikukood',
    INVALID_GENDER = 'Vigane sugu',
    INVALID_POSITION = 'Vigane ametikoht',
    INVALID_RADIATION_WORK_START_YEAR = 'Vigane Kiirgustööle asumise aasta',
    INVALID_RADIATION_WORKER_CATEGORY = 'Vigane Kiirgustöötaja kategooria',
    INVALID_REGISTRATION_DETAILS = 'Vigased andmed regiistreerimise kohta',
    INVALID_MODEL = 'Vigane mudel',
    INVALID_SERIAL_NO = 'Vigane seeria nr',
    INVALID_MAXIMUM_PIPE_VOLTAGE = 'Vigane maksimum torupinge',
    INVALID_MAXIMUM_CURRENT = 'Vigane maksimum voolutugevus',
    INVALID_MANUFACTURER = 'Vigane tootja',
    INVALID_MANUFACTURER_COUNTRY = 'Vigane tootja riik',
    INVALID_X_RAY_SERIAL_NO = 'Vigane seeria number',
    INVALID_X_RAY_SERIAL_MODEL = 'Vigane röntgentoru seeria number',
    INVALID_USAGE_TYPE = 'Vigane kasutus',
    INVALID_RADIATION_STATUS_TYPE = 'Vigane seadme status',
    INVALID_MANUFACTURE_YEAR = 'Vigane seadme valmistamis aasta',
    INVALID_MAINTENANCE_INTERVAL_MONTHS = 'Vigane seadme hooldusvälp',
    NO_CABINET = 'Kabineti pole valitud',
    NO_CLINIC = 'Kliinik pole valitud',
    INVALID_EQUIPMENT_NAME = 'Vigane seadme nimi',
    INVALID_MAINTENANCE_INTERVAL_YEARS = 'Vigane seadme hooldusvälp',
    INVALID_LAST_MAINTENANCE_DATE = 'Vigane viimase hoolduse kuupäev',
    INVALID_LAST_MEASUREMENT_DATE = 'Vigane viimase mõõdistamise kuupäev',
    INVALID_IMAGES = 'Vigased ülesvõtted',
    NO_VALID_INPUT = "Vigased andmed",
    INVALID_CABINET_LICENCE_NO = "Vigane kabineti litsentsi number",
    CURRENT_USERS_LIMIT = 'Aktiivsete töötajate arv on täis',
    WORKER_WITH_GIVEN_PERSONAL_NO_EXISTS = 'Antud isikukoodiga töötaja on juba lisatud',
    MANAGER_HAVE_CLINICS = "Palun eemalde kliinikumid managerilt",
    PLEASE_REMOVE_X_RAY_EQUIPMENT_FROM_CABINET = "Palun eemalda röntgenseadmed kabinetilt",
    PLEASE_REMOVE_CABINETS_FROM_CLINIC = 'Palun eemalda kabinetid kliinikult',

}

class AppStore {
    toast = useToast()


    protected state = reactive<AppStoreState>({
        loading: []
    })


    public getState() {
        return readonly(this.state)
    }

    public addLoading(): number {
        const loadingID = new Date().getTime()
        this.state.loading.push(loadingID)
        return loadingID
    }

    public removeLoading(loadingID: number) {
        const loadingIndex = this.state.loading.findIndex(l => l === loadingID)
        this.state.loading.splice(loadingIndex, 1)
    }

    public showToast(type: TYPE, message: string) {
        this.toast(this.getMessage(message), {type})
    }

    private getMessage(message: string): ErrorType | string {
        const knownErrorType = Object.entries(ErrorType).find(e => e[0] === message)
        return knownErrorType ? knownErrorType[1] : message || 'Miskit läks valesti'
    }
}

const appStore = new AppStore()
export const useAppStore = (): AppStore => appStore
