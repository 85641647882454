import {TableData} from "@/components/clinics/reports/pdfgenerator/pdf-parts/example-data";

export interface Roles {
    superAdmin: boolean,
    admin: boolean,
    manager: boolean,
    user: boolean
}

export interface AddUserInput {
    readonly name: string
    readonly email: string
    readonly phone: string
    readonly password: string
}

export interface EditUserInput extends Omit<AddUserInput, 'password'> {
    readonly uid: string
}

export interface ToggleSuperAdminRoleDto {
    readonly email?: string,
    readonly superAdmin?: boolean
}

// TODO can we make it type safe @ functions also

export enum FunctionName {
    UPDATE_AUTH_USER_PHONE = 'updateAuthUserPhone',
    EDIT_AUTH_USER = 'editAuthUser',
    TOGGLE_SUPER_ADMIN_ROLE = "toggleSuperAdminRole",
    ADD_ADMIN = "addAdmin",
    GET_ADMINS = "getAdmins",
    GET_MANAGERS = "getManagers",
    DELETE_ADMIN = "deleteAdmin",
    DELETE_MANAGER = "deleteManager",
    ADD_MANAGER = "addManager",
    ADD_CLINIC = "addClinic",
    GET_CLINICS = "getClinics",
    EDIT_CLINIC = 'editClinic',
    EDIT_CLINIC_SETTINGS = 'editClinicSettings',
    DELETE_CLINIC = 'deleteClinic',
    ADD_WORKER = "addWorker",
    GET_WORKERS = "getWorkers",
    EDIT_WORKER = "editWorker",
    TOGGLE_WORKER_STATUS = "toggleWorkerStatus",
    DELETE_WORKER = "deleteWorker",
    ADD_CABINET = "addCabinet",
    GET_CABINETS = "getCabinets",
    DELETE_CABINET = "deleteCabinet",
    EDIT_CABINET = 'editCabinet',
    GET_EQUIPMENTS = "getEquipments",
    ADD_X_RAY_EQUIPMENT = "addXRayEquipment",
    GET_X_RAY_EQUIPMENTS = "getXRayEquipments",
    DELETE_X_RAY_EQUIPMENT = "deleteXRayEquipment",
    EDIT_X_RAY_EQUIPMENT = "editXrayEquipment",
    ADD_X_RAY_IMAGES = "addXRayImages",
    GET_X_RAY_IMAGES_BY_IDS = "getXRayImagesByIDs",
    GET_ALL_X_RAY_IMAGES = 'getAllXRayImages',
    GET_SNAPSHOTS = "getSnapshots",
    ADD_SNAPSHOTS = 'addSnapshots',
    ADD_CONTACT = 'addContact',
    GET_NOTIFICATIONS = 'getNotifications',
    GET_PAST_WORKERS = 'getPastWorkers',

    // TODO: should never arrive to production
    MANUAL_RUN = 'manualRun'
}

export interface GetUserResult {
    readonly uid: string
    readonly name?: string
    readonly email?: string
    readonly phone?: string
    readonly disabled: boolean
}

export interface GetAdminResult extends GetUserResult {
    readonly superAdmin: boolean
}

export interface EditClinicInput extends AddClinicInput {
    readonly uid: string
}


export interface AddClinicInput {
    readonly name: string
    readonly radiationPracticeLicenseHolder: string
    readonly managerUID: string
    readonly alternativeManagerUID: string|null
    readonly usersCount: number
    readonly licenseNos: string[]
}

export interface ClinicDto extends AddClinicInput {
    readonly uid: string
    readonly active: boolean
    readonly workersUID: string[]
    readonly pastWorkersUID: string[]
    readonly cabinetsUID: string[]
    readonly licenceLocation?: string
    readonly rapporteurName?: string
    readonly rapporteurEmail?: string
    readonly rapporteurPhone?: string
    readonly accountantEmail?: string
    readonly logo?: string
}

export enum GENDER {
    MAN = "MAN",
    WOMAN = "WOMAN"
}

export const GENDERS: Record<GENDER, string> = {
    [GENDER.MAN]: 'Mees',
    [GENDER.WOMAN]: 'Naine'
}

export interface AddWorkerInput {
    name: string
    personalNo: number
    gender: GENDER
    position: string
    radiationWorkStartYear: number
    radiationWorkerCategory: string
    registrationDetails: string
    clinicUID: string,
    lastRadiationSafetyTrainingTime: number | null
}

export interface EditWorkerInput extends AddWorkerInput {
    readonly uid: string
}

export interface ToggleWorkerStatusInput {
    readonly uid: unknown,
    readonly status: unknown
}

export interface WorkerDto extends AddWorkerInput {
    readonly uid: string
    readonly active: boolean
}

export interface EditCabinetInput extends AddCabinetInput {
    readonly uid: string
}

export interface AddCabinetInput {
    readonly name: string
    readonly clinicUID: string
    readonly licenceNo: string
}

export interface CabinetDto extends AddCabinetInput {
    readonly uid: string
    readonly active: boolean
    readonly xRayEquipmentsUID: string[]
}

export interface EquipmentDto {
    readonly uid: string
    readonly name: string
    readonly maintenanceIntervalYears: number
}

export enum XRayEquipmentUsageType {
    MOBILE = "MOBILE",
    STATIONARY = "STATIONARY"
}

export const X_RAY_EQUIPMENT_USAGES: Record<XRayEquipmentUsageType, string> = {
    [XRayEquipmentUsageType.MOBILE]: 'Mobiilne',
    [XRayEquipmentUsageType.STATIONARY]: 'Statsionaarne'
}

export enum RadiationStatusType {
    USE = "USE",
    STORAGE = "STORAGE"
}

export const RADIATION_STATUSES: Record<RadiationStatusType, string> = {
    [RadiationStatusType.USE]: 'Kasutamine',
    [RadiationStatusType.STORAGE]: 'Hoiustamine'
}

export interface EditXRayEquipmentInput extends Omit<AddXRayEquipmentInput, 'cabinetUID' | 'name' | 'equipmentUID' | 'maintenanceIntervalYears' | 'imageUIDs'> {
    readonly uid: string
}

export interface AddXRayEquipmentInput {
    equipmentUID: string
    name: string
    maintenanceIntervalYears: number
    maintenanceIntervalsMonths: number
    lastMaintenanceDate: number
    lastMeasurementDate: number
    model: string
    serialNo: string
    maximumPipeVoltage: number
    maximumCurrent: number
    manufacturer: string
    manufacturerCountry: string
    xRayTubeSerialNo: string
    xRayTubeModel: string
    cabinetUID: string
    usageType: XRayEquipmentUsageType
    radiationStatusType: RadiationStatusType
    manufactureYear: number
    imageUIDs: string[]
}

export interface XRayEquipmentDto extends Readonly<AddXRayEquipmentInput> {
    readonly uid: string
    readonly active: boolean
    readonly licenceNo: string
}

export interface AddXRayImageInput {
    imageName: string
    secondsRate: number
    doseRate: number
}

export interface XRayImageDto extends Readonly<AddXRayImageInput> {
    readonly uid: string
}

export interface SnapshotPicture {
    readonly label: string
    readonly value: number
    readonly xRayEquipmentUID: string
    readonly xRayImageId: string
}

export type AddSnapshotInput = Omit<SnapshotDTO, 'uid'>

export interface SnapshotDTO {
    readonly uid: string
    readonly cabinetUID: string
    readonly pictures: SnapshotPicture[]
    readonly timestamp: number
    readonly workersUIDs: string[]
}

export interface PDFDataInput extends Omit<TableData, 'number'> {
    readonly workerUID: string
}

export interface ContactDTO {
    readonly uid: string
    readonly timestamp: number
    readonly active: boolean
    readonly activeSwitchTime: number | null
    readonly activeSwitchAdminUID: string | null
    readonly senderUID: string
    readonly topic: string
    readonly content: string
}

export type AddContactInput = Pick<ContactDTO, "topic" | "content">

export enum NotificationType {
    ACTIVE_USER_COUNT_FULL = "ACTIVE_USER_COUNT_FULL",
    NEW_USER_COUNT = "NEW_USER_COUNT",
    NEW_CABINET = "NEW_CABINET",
    NEW_X_RAY_EQUIPMENT = "NEW_X_RAY_EQUIPMENT",
    MAINTENANCE_DATE_APPROACHING = "MAINTENANCE_DATE_APPROACHING",
    MEASUREMENT_DATE_APPROACHING = "MEASUREMENT_DATE_APPROACHING"
}

export interface NotificationDTO {
    readonly uid: string
    readonly timestamp: number
    readonly active: true
    readonly type: NotificationType
    readonly value: string | null
    readonly title: string
    readonly message: string
    readonly description: string | null
    readonly clinicUID: string
    readonly clinicName: string
    readonly cabinetUID: string | null
    readonly xRayEquipmentUID: string | null
    readonly workerUID: string | null
    readonly createdByUID: string | null
}


