import {addAdminFun, deleteAdminFun, getAdminsFun, setSuperAdminFun} from "@/firebase/functions";
import {AddUserInput, ToggleSuperAdminRoleDto} from "@/shared/types";
import {reactive, readonly} from "@vue/reactivity";
import {TYPE} from "vue-toastification";
import {useAppStore} from "../app/AppStore";
import {AdminsStoreState} from "./types";


class AdminsStore {
    private appStore = useAppStore()

    protected state = reactive<AdminsStoreState>({
        admins: [],
        loaded: false
    })

    public resetState(){
        this.state.admins = []
        this.state.loaded = false
    }

    public getState() {
        return readonly(this.state)
    }

    public async setAdmins(force: boolean) {
        if (!this.state.loaded || force) {
            try {
                this.state.admins = await getAdminsFun()
                this.state.loaded = true
            } catch (err: any) {
                this.appStore.showToast(TYPE.ERROR, err.message)
            }
        }
    }

    public async setSuperAdmin(data: ToggleSuperAdminRoleDto) {
        try {
            await setSuperAdminFun(data)
            await this.setAdmins(true)
            this.appStore.showToast(TYPE.SUCCESS, data.superAdmin ? 'Superadmin lisatud' : 'Superadmin eemaldatud')
        } catch (err: any) {
            this.appStore.showToast(TYPE.ERROR, err.message)
        }
    }

    public async addAdmin(newAdmin: AddUserInput) {
        try {
            await addAdminFun(newAdmin)
            await this.setAdmins(true)
            this.appStore.showToast(TYPE.SUCCESS, 'Admin lisatud')
        } catch (err: any) {
            this.appStore.showToast(TYPE.ERROR, err.message)
        }
    }

    public async deleteAdmin(email: string) {
        try {
            await deleteAdminFun(email)
            await this.setAdmins(true)
            this.appStore.showToast(TYPE.SUCCESS, 'Admin kustutatud')
        } catch (err: any) {
            this.appStore.showToast(TYPE.ERROR, err.message)
        }
    }
}

const store = new AdminsStore()
export const useAdminsStore = (): AdminsStore => store
