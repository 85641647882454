import {useAppStore} from "@/store/app/AppStore";
import {reactive, readonly} from "vue";
import {XRayEquipmentsStoreState} from "@/store/xrayequipments/types";
import {AddXRayEquipmentInput, EditXRayEquipmentInput} from "@/shared/types";
import {
    addXRayEquipmentFun,
    deleteXRayEquipmentFun,
    editXRayEquipmentFun,
    getXRayEquipmentsFun
} from "@/firebase/functions";
import {TYPE} from "vue-toastification";

class XRayEquipmentsStore {
    private appStore = useAppStore()
    private state = reactive<XRayEquipmentsStoreState>({
        xRayEquipments: [],
        loaded: false
    })

    public resetState() {
        this.state.xRayEquipments = []
        this.state.loaded = false
    }

    public getState() {
        return readonly(this.state)
    }

    public async setXRayEquipments(force: boolean) {
        if (!this.state.loaded || force) {
            try {
                this.state.xRayEquipments = await getXRayEquipmentsFun()
                this.state.loaded = true
            } catch (err: any) {
                this.appStore.showToast(TYPE.ERROR, err.message)
            }
        }
    }

    public async addXRayEquipment(newXRayEquipment: AddXRayEquipmentInput) {
        try {
            await addXRayEquipmentFun(newXRayEquipment)
            await this.setXRayEquipments(true)
            this.appStore.showToast(TYPE.SUCCESS, 'Uus seade lisatud')
        } catch (err: any) {
            this.appStore.showToast(TYPE.ERROR, err.message)
        }
    }

    public async deleteXRayEquipment(xRayEquipmentUID: string) {
        try {
            await deleteXRayEquipmentFun(xRayEquipmentUID)
            await this.setXRayEquipments(true)
            this.appStore.showToast(TYPE.SUCCESS, 'Seade kustutatud')
        } catch (err: any) {
            this.appStore.showToast(TYPE.ERROR, err.message)
        }
    }

    public async editXRayEquipment(xRayEquipment: EditXRayEquipmentInput) {
        try {
            await editXRayEquipmentFun(xRayEquipment)
            await this.setXRayEquipments(true)
            this.appStore.showToast(TYPE.SUCCESS, 'Seade muudetud')
        } catch (e: any) {
            this.appStore.showToast(TYPE.ERROR, e.message)
        }
    }
}

const store = new XRayEquipmentsStore()
export const useXRayEquipmentsStore = (): XRayEquipmentsStore => store;
