import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyACKcDs-KTSOdNyym9-Bj8Dl44vXuy-kTk',
  authDomain: 'toothy-admin.firebaseapp.com',
  databaseURL: 'https://toothy-admin-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'toothy-admin',
  storageBucket: 'toothy-admin.appspot.com',
  messagingSenderId: '634060072572',
  appId: '1:634060072572:web:ab140cb43195f13f0a8bf9'
}

firebase.initializeApp(firebaseConfig)
const db = firebase.firestore()
const auth = firebase.auth()
const functions = firebase.functions()
const storage = firebase.storage()
// db.useEmulator('localhost', 8080)
// functions.useEmulator('localhost', 5001)

export { db, auth, functions, storage }
