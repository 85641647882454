import {useAppStore} from "@/store/app/AppStore";
import {reactive, readonly} from "vue";
import {ContacsStoreState} from "@/store/contacts/types";
import {AddContactInput} from "@/shared/types";
import {addContactFun} from "@/firebase/functions";
import {TYPE} from "vue-toastification";

class ContactsStore {
    private appStore = useAppStore()
    protected state = reactive<ContacsStoreState>({
        contacts: [],
        loaded: false
    })

    public resetState() {
        this.state.contacts = []
        this.state.loaded = false
    }

    public getState() {
        return readonly(this.state)
    }

    public async addContact(contact: AddContactInput) {
        try {
            await addContactFun(contact)
            this.appStore.showToast(TYPE.SUCCESS, 'Kiri saadetud!')
        } catch (err: any) {
            this.appStore.showToast(TYPE.ERROR, err.message)
        }
    }
}

const store = new ContactsStore()
export const useContactsStore = (): ContactsStore => store
