import {
    addWorkerFun,
    deleteWorkerFun,
    editWorkerFun,
    getPastWorkersFun,
    getWorkersFun,
    toggleWorkerStatusFun
} from "@/firebase/functions";
import {AddWorkerInput, EditWorkerInput, ToggleWorkerStatusInput} from "@/shared/types";
import {reactive} from "@vue/reactivity";
import {TYPE} from "vue-toastification";
import {useAppStore} from "../app/AppStore";
import {MaxWorkerCountWarningType, WorkersStoreState} from "./types";
import {readonly} from "vue";

class WorkersStore {
    private appStore = useAppStore()
    protected state = reactive<WorkersStoreState>({
        workers: [],
        pastWorkers: [],
        loaded: false,
        pastWorkersLoaded: false,
        maxWorkerCountWarning: undefined
    })

    public resetState() {
        this.state.workers = []
        this.state.pastWorkers = []
        this.state.loaded = false
        this.state.pastWorkersLoaded = false
        this.state.maxWorkerCountWarning = undefined
    }

    public getState() {
        return readonly(this.state)
    }

    public setMaxWorkerCountWarning(status?: MaxWorkerCountWarningType) {
        this.state.maxWorkerCountWarning = status
    }

    public async setWorkers(force: boolean) {
        if (!this.state.loaded || force) {
            try {
                this.state.workers = await getWorkersFun()
                this.state.loaded = true
            } catch (err: any) {
                this.appStore.showToast(TYPE.ERROR, err.message)
            }
        }
    }

    public async setPastWorkers(force: boolean) {
        if (this.state.pastWorkersLoaded && !force) return
        try {
            this.state.pastWorkers = await getPastWorkersFun()
            this.state.pastWorkersLoaded = true
        } catch (e: any) {
            this.appStore.showToast(TYPE.ERROR, e.message)
        }
    }

    public async addWorker(newWorker: AddWorkerInput) {
        try {
            const {isMaxActive} = await addWorkerFun(newWorker)
            await this.setWorkers(true)
            if (isMaxActive) this.setMaxWorkerCountWarning(MaxWorkerCountWarningType.ADD_NEW)
            else this.appStore.showToast(TYPE.SUCCESS, 'Kiirgustöötaja lisatud')
        } catch (err: any) {
            this.appStore.showToast(TYPE.ERROR, err.message)
        }
    }

    public async deleteWorker(data: { workerUID: string, clinicUID: string }) {
        try {
            await deleteWorkerFun(data)
            await Promise.allSettled([
                this.setWorkers(true),
                this.setPastWorkers(true)
            ])
            this.appStore.showToast(TYPE.SUCCESS, "Kiirgustöötaja eemaldatud")
        } catch (err: any) {
            this.appStore.showToast(TYPE.ERROR, err.message)
        }
    }

    public async editWorker(worker: EditWorkerInput) {
        try {
            await editWorkerFun(worker)
            await this.setWorkers(true)
            this.appStore.showToast(TYPE.SUCCESS, 'Töötaja muudetud')
        } catch (e: any) {
            this.appStore.showToast(TYPE.ERROR, e.message)
        }
    }

    public async toggleWorkerStatus(data: ToggleWorkerStatusInput) {
        try {
            await toggleWorkerStatusFun(data)
            await this.setWorkers(true)
            this.appStore.showToast(TYPE.SUCCESS, 'Töötaja staatus muudetud')
        } catch (e: any) {
            if (e.message === "MAX_ACTIVE_USERS") this.setMaxWorkerCountWarning(MaxWorkerCountWarningType.TOGGLE_STATUS)
            else this.appStore.showToast(TYPE.ERROR, e.message)
        }
    }
}

const store = new WorkersStore()
export const useWorkersStore = (): WorkersStore => store
