import {useAppStore} from "@/store/app/AppStore";
import {reactive} from "vue";
import {CabinetsStoreState} from "@/store/cabinets/types";
import {AddCabinetInput, EditCabinetInput} from "@/shared/types";
import {TYPE} from "vue-toastification";
import {addCabinetFun, deleteCabinetFun, editCabinetFun, getCabinetsFun} from "@/firebase/functions";

class CabinetsStore {
    private appStore = useAppStore()
    protected state = reactive<CabinetsStoreState>({
        cabinets: [],
        loaded: false
    })

    public resetState(){
        this.state.cabinets = []
        this.state.loaded = false
    }

    public getState() {
        return reactive(this.state)
    }

    public async setCabinets(force: boolean) {
        if (!this.state.loaded || force) {
            try {
                this.state.cabinets = await getCabinetsFun()
                this.state.loaded = true
            } catch (err: any) {
                this.appStore.showToast(TYPE.ERROR, err.message)
            }
        }
    }

    public async addCabinet(newCabinet: AddCabinetInput) {
        try {
            await addCabinetFun(newCabinet)
            await this.setCabinets(true)
            this.appStore.showToast(TYPE.SUCCESS, 'Kabinet lisatud')
        } catch (err: any) {
            this.appStore.showToast(TYPE.ERROR, err.message)
        }
    }

    public async deleteCabinet(data: { cabinetUID: string, clinicUID: string }) {
        try {
            await deleteCabinetFun(data)
            await this.setCabinets(true)
            this.appStore.showToast(TYPE.SUCCESS, 'Kabinet kustutatud')
        } catch (e: any) {
            this.appStore.showToast(TYPE.ERROR, e.message)
        }
    }

    public async editCabinet(cabinet: EditCabinetInput) {
        try {
            await editCabinetFun(cabinet)
            await this.setCabinets(true)
            this.appStore.showToast(TYPE.SUCCESS, 'Kabinet muudetud')
        } catch (e: any) {
            this.appStore.showToast(TYPE.ERROR, e.message)
        }
    }
}

const store = new CabinetsStore()
export const useCabinetsStore = (): CabinetsStore => store
