import {useAppStore} from "@/store/app/AppStore";
import {reactive, readonly} from "vue";
import {ImagesStoreState} from "@/store/images/types";
import {getAllXRayImagesFun} from "@/firebase/functions";
import {TYPE} from "vue-toastification";
import {useUserStore} from "@/store/user/UserStore";

class ImagesStore {
    private appStore = useAppStore()
    private userStore = useUserStore()
    private state = reactive<ImagesStoreState>({
        images: [],
        loaded: false
    })

    public resetState() {
        this.state.images = []
        this.state.loaded = false
    }

    public getState() {
        return readonly(this.state)
    }

    public async setImages(force: boolean) {
        if (!this.userStore.getState().admin && !this.userStore.getState().manager) return
        if (this.state.loaded && !force) return
        try {
            this.state.images = await getAllXRayImagesFun()
            this.state.loaded = true
        } catch (e: any) {
            this.appStore.showToast(TYPE.ERROR, e.message)
        }
    }
}

const store = new ImagesStore()
export const useImagesStore = (): ImagesStore => store
