import { useAppStore } from "@/store/app/AppStore"
import { auth } from "./config"

const appStore = useAppStore()

export function signIn(email: string, password: string): Promise<undefined> {
  const loadingID = appStore.addLoading()
  return new Promise((resolve, reject) => {
    auth.signInWithEmailAndPassword(email, password)
      .then(() => {
        appStore.removeLoading(loadingID)
        resolve(undefined)
      })
      .catch(error => {
        appStore.removeLoading(loadingID)
        reject(error)
      })
  })
}

export function forgotPassword(email: string): Promise<undefined> {
    const loadingID = appStore.addLoading()
    return new Promise((resolve, reject) => {
        auth.sendPasswordResetEmail(email, {url: 'https://toothy-admin.web.app/'})
            .then(() => resolve(undefined))
            .catch(error => reject(error))
            .finally(() => appStore.removeLoading(loadingID))
    })
}
