import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Home from '../views/Home.vue'
import {RouteName, RoutePath} from './types'
import {useUserStore} from '@/store/user/UserStore'

const userStore = useUserStore()
const userState = userStore.getState()

const routes: Array<RouteRecordRaw> = [
    {
        path: RoutePath.HOME,
        name: RouteName.HOME,
        component: Home
    },
    {
        path: RoutePath.INSTALL,
        name: RouteName.INSTALL,
        component: () => import(/* webpackChunkName: "installeerimine" */ '@/views/Installeerimine.vue'),
    },
    {
        path: RoutePath.FORGOT_PASSWORD,
        name: RouteName.FORGOT_PASSWORD,
        component: () => import(/* webpackChunkName: "forgotPassword" */ '@/views/ForgotPassword.vue'),
    },
    {
        path: RoutePath.DASHBOARD,
        name: RouteName.DASHBOARD,
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
        beforeEnter: (_, __, next) => {
            userState.manager ? next() : next({name: RouteName.HOME})
        },
        children: [
            {
                path: RoutePath.DASHBOARD_HOME,
                name: RouteName.DASHBOARD_HOME,
                component: () => import(/* webpackChunkName: "dashboardHome" */ '@/views/dashboard/DashboardHome.vue')
            },
            {
                path: RoutePath.SETTINGS,
                name: RouteName.SETTINGS,
                component: () => import(/* webpackChunkName: "settings" */ '@/views/dashboard/Settings.vue'),
            },
            {
                path: RoutePath.ADMINISTRATORS,
                name: RouteName.ADMINISTRATORS,
                component: () => import(/* webpackChunkName: "administrators" */ '@/views/dashboard/Administrators.vue'),
                beforeEnter: (_, __, next) => {
                    userState.superAdmin ? next() : next({name: RouteName.HOME})
                },
            },
            {
                path: RoutePath.MANAGERS,
                name: RouteName.MANAGERS,
                component: () => import(/* webpackChunkName: "managers" */ '@/views/dashboard/Managers.vue'),
                beforeEnter: (_, __, next) => userState.admin ? next() : next({name: RouteName.HOME})
                ,
            },
            {
                path: RoutePath.CONTACT,
                name: RouteName.CONTACT,
                component: () => import(/* webpackChunkName: "contact" */ '@/views/dashboard/Contact.vue'),
                beforeEnter: (_, __, next) => userState.manager ? next() : next({name: RouteName.HOME})

            },
            {
                path: RoutePath.CLINICS,
                name: RouteName.CLINICS,
                component: () => import(/* webpackChunkName: "clinics" */ '@/views/dashboard/Clinics.vue'),
                children: [
                    {
                        path: RoutePath.CLINICS_HOME,
                        name: RouteName.CLINICS_HOME,
                        component: () => import(/* webpackChunkName: "clinicsHome" */ '@/views/dashboard/clinics/ClinicsHome.vue'),
                    },
                    {
                        path: RoutePath.CLINISC_WORKERS,
                        name: RouteName.CLINICS_WORKERS,
                        component: () => import(/* webpackChunkName: "clinicsWorkers" */ '@/views/dashboard/clinics/ClinicsWorkers.vue'),
                    },
                    {
                        path: RoutePath.CLINICS_CABINETS,
                        name: RouteName.CLINICS_CABINETS,
                        component: () => import(/* webpackChunkName: "clinicsCabinets" */ '@/views/dashboard/clinics/ClinicsCabinets.vue'),
                    },
                    {
                        path: RoutePath.CLINICS_X_RAY_EQUIPMENTS,
                        name: RouteName.CLINICS_X_RAY_EQUIPMENTS,
                        component: () => import(/* webpackChunkName: "clinicsXrayEquipments" */ '@/views/dashboard/clinics/ClinicsXRayEquipments.vue'),
                    },
                    {
                        path: RoutePath.CLINICS_X_RAY_IMAGES,
                        name: RouteName.CLINICS_X_RAY_IMAGES,
                        component: () => import(/* webpackChunkName: "clinicsXrayImages" */ '@/views/dashboard/clinics/ClinicsXRayImages.vue'),
                    },
                    {
                        path: RoutePath.CLINICS_REPORTS,
                        name: RouteName.CLINICS_REPORTS,
                        component: () => import(/* webpackChunkName: "clinicsReports" */ '@/views/dashboard/clinics/ClinicsReports.vue'),
                    },
                    {
                        path: RoutePath.CLINICS_SETTINGS,
                        name: RouteName.CLINICS_SETTINGS,
                        component: () => import(/* webpackChunkName: "clinicsSettings" */ '@/views/dashboard/clinics/ClinicsSettings.vue'),
                    },
                ]
            },
            {
                path: RoutePath.DOCUMENTATION,
                name: RouteName.DOCUMENTATION,
                component: () => import(/* webpackChunkName: "documentation" */ '@/views/dashboard/Documentation.vue'),
                children: [
                    {
                        path: RoutePath.DOCUMENTATION_HOME,
                        name: RouteName.DOCUMENTATION_HOME,
                        component: () => import(/* webpackChunkName: "documentationHome" */ '@/views/dashboard/documentation/DocumentationHome.vue'),
                    },
                    {
                        path: RoutePath.DOCUMENTATION_INSTALL,
                        name: RouteName.DOCUMENTATION_INSTALL,
                        beforeEnter: (_, __, next) => userState.admin ? next() : next({name: RouteName.HOME}),
                        component: () => import(/* webpackChunkName: "documentationInstall" */ '@/views/dashboard/documentation/DocumentationInstall.vue'),
                    },
                    {
                        path: RoutePath.DOCUMENTATION_UNINSTALL,
                        name: RouteName.DOCUMENTATION_UNINSTALL,
                        beforeEnter: (_, __, next) => userState.admin ? next() : next({name: RouteName.HOME}),
                        component: () => import(/* webpackChunkName: "documentationUninstall" */ '@/views/dashboard/documentation/DocumentationUninstall.vue'),
                    },
                    {
                        path: RoutePath.DOCUMENTATION_USER_MANUAL,
                        name: RouteName.DOCUMENTATION_USER_MANUAL,
                        component: () => import(/* webpackChunkName: "documentationUserManual" */ '@/views/dashboard/documentation/DocumentationUserManual.vue'),
                    },

                ]
            },
            // {
            //     path: RoutePath.VIDEO_HELP,
            //     name: RouteName.VIDEO_HELP,
            //     component: () => import(/* webpackChunkName: "videohelp" */ '@/views/dashboard/VideoHelp.vue'),
            //     children: [
            //         {
            //             path: RoutePath.VIDEO_HELP_HOME,
            //             name: RouteName.VIDEO_HELP_HOME,
            //             component: () => import(/* webpackChunkName: "videohelphome" */ '@/views/dashboard/videohelp/VideoHelpHome.vue'),
            //         },
            //         {
            //             path: RoutePath.VIDEO_HELP_SHARE,
            //             name: RouteName.VIDEO_HELP_SHARE,
            //             component: () => import(/* webpackChunkName: "videhelpshare" */ '@/views/dashboard/videohelp/VideHelpShare.vue'),
            //         },
            //         {
            //             path: RoutePath.VIDEO_HELP_JOIN,
            //             name: RouteName.VIDEO_HELP_JOIN,
            //             component: () => import(/* webpackChunkName: "videhelpjoin" */ '@/views/dashboard/videohelp/join/VideoHelpJoin.vue'),
            //         },
            //     ]
            // }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
