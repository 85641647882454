import {reactive, readonly} from "@vue/reactivity";
import {Claims, UserStoreState} from "./types";
import {updateAuthUserPhoneFun} from "@/firebase/functions";
import {useAppStore} from "@/store/app/AppStore";
import {TYPE} from "vue-toastification";


class UserStore {
    private appStore = useAppStore()
    protected state = reactive<UserStoreState>({
        superAdmin: false,
        admin: false,
        manager: false,
        user: false,
        name: undefined,
        email: undefined
    })

    public resetState(): void {
        this.state.superAdmin = false
        this.state.admin = false
        this.state.manager = false
        this.state.user = false
        this.state.name = undefined
        this.state.email = undefined
    }

    public getState() {
        return readonly(this.state)
    }

    public setUser(param: { claims?: Claims, name?: string, email?: string }): void {
        const {claims, name, email} = param
        if (claims) {
            const {superAdmin, admin, manager, user} = claims
            this.state.superAdmin = superAdmin
            this.state.admin = admin
            this.state.manager = manager
            this.state.user = user
        }
        this.state.name = name
        this.state.email = email
    }

    public setEmail(email?: string) {
        this.state.email = email
    }

    public setName(name?: string) {
        this.state.name = name
    }

    public async updateAuthUserPhone(phone: string) {
        try {
            await updateAuthUserPhoneFun(phone)
            this.appStore.showToast(TYPE.SUCCESS, 'Telefon edukalt muudetud')
        } catch (err: any) {
            this.appStore.showToast(TYPE.ERROR, err.message)
        }
    }
}

const store = new UserStore()
export const useUserStore = (): UserStore => store

